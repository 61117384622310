"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCommitMappingsToCommit = void 0;
function convertCommitMappingsToCommit(commitMappings) {
    var commit = {
        id: commitMappings.sha,
        short_id: commitMappings.sha,
        created_at: commitMappings.commit.committer.date,
        parent_ids: commitMappings.parents.map(function (parent) { return parent.sha; }),
        title: commitMappings.commit.message,
        message: commitMappings.commit.message,
        author_name: commitMappings.commit.author.name,
        author_email: commitMappings.commit.author.email,
        authored_date: commitMappings.commit.author.date,
        committer_name: commitMappings.commit.committer.name,
        committer_email: commitMappings.commit.committer.email,
        committed_date: commitMappings.commit.committer.date,
        web_url: commitMappings.html_url,
        stats: [],
    };
    return commit;
}
exports.convertCommitMappingsToCommit = convertCommitMappingsToCommit;
