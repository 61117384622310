"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Repository = void 0;
var Repository;
(function (Repository) {
    var AccessLevel;
    (function (AccessLevel) {
        AccessLevel["editor"] = "editor";
        AccessLevel["viewer"] = "viewer";
    })(AccessLevel = Repository.AccessLevel || (Repository.AccessLevel = {}));
    var Role;
    (function (Role) {
        Role["owner"] = "owner";
        Role["collaborator"] = "collaborator";
    })(Role = Repository.Role || (Repository.Role = {}));
})(Repository = exports.Repository || (exports.Repository = {}));
