"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StagedScoreOverride = exports.ScoreOverride = void 0;
var ScoreOverride;
(function (ScoreOverride) {
    function computeScore(override, computed) {
        if (override === null || override === void 0 ? void 0 : override.exclude) {
            return 0;
        }
        return +((override === null || override === void 0 ? void 0 : override.score) || computed || 0);
    }
    ScoreOverride.computeScore = computeScore;
    function hasOverride(override) {
        return (override === null || override === void 0 ? void 0 : override.exclude) || !!(override === null || override === void 0 ? void 0 : override.score);
    }
    ScoreOverride.hasOverride = hasOverride;
})(ScoreOverride = exports.ScoreOverride || (exports.ScoreOverride = {}));
var StagedScoreOverride;
(function (StagedScoreOverride) {
    function getDiffOverrides(overrides) {
        return overrides
            .filter(function (override) { return parseEntityId(override.id).entity === 'Diff'; });
    }
    StagedScoreOverride.getDiffOverrides = getDiffOverrides;
    function getCommitOverrides(overrides) {
        return overrides
            .filter(function (override) { return parseEntityId(override.id).entity === 'Commit'; });
    }
    StagedScoreOverride.getCommitOverrides = getCommitOverrides;
    function getMergeRequestOverrides(overrides) {
        return overrides
            .filter(function (override) { return parseEntityId(override.id).entity === 'MergeRequest'; });
    }
    StagedScoreOverride.getMergeRequestOverrides = getMergeRequestOverrides;
    function parseEntityId(entityId) {
        var _a = entityId.split('/'), entity = _a[0], id = _a[1];
        return { entity: entity, id: id };
    }
    StagedScoreOverride.parseEntityId = parseEntityId;
})(StagedScoreOverride = exports.StagedScoreOverride || (exports.StagedScoreOverride = {}));
