"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = void 0;
var FileType;
(function (FileType) {
    FileType["typescript"] = "typescript";
    FileType["html"] = "html";
    FileType["java"] = "java";
    FileType["javascript"] = "javascript";
    FileType["css"] = "css";
    FileType["python"] = "python";
    FileType["c"] = "c";
    FileType["cpp"] = "cpp";
    FileType["json"] = "json";
    FileType["markdown"] = "markdowm";
    FileType["haskell"] = "haskell";
    FileType["sql"] = "sql";
})(FileType = exports.FileType || (exports.FileType = {}));
