"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertNoteMappingsToNote = void 0;
var Note_1 = require("../Note");
function convertNoteMappingsToNote(noteData) {
    var components = noteData.html_url.split('/');
    var note_type = components[components.length - 2] === 'issues'
        ? Note_1.Note.NoteableType.issue
        : Note_1.Note.NoteableType.merge_request;
    var note = {
        id: noteData.id,
        body: noteData.body,
        attachment: 'n/a',
        author: {
            id: noteData.user.id,
            username: noteData.user.login,
            email: 'n/a',
            name: noteData.user.login,
            state: noteData.user.type,
            created_at: 'n/a',
        },
        created_at: noteData.created_at,
        updated_at: noteData.updated_at,
        system: false,
        noteable_id: noteData.id,
        noteable_type: note_type,
        noteable_iid: noteData.id,
        resolvable: false,
        confidential: false,
    };
    return note;
}
exports.convertNoteMappingsToNote = convertNoteMappingsToNote;
