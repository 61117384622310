"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Extensions = void 0;
var Extensions;
(function (Extensions) {
    function updateResource(resource, newProperties) {
        var extensions = resource.extensions, oldProperties = __rest(resource, ["extensions"]);
        return __assign(__assign(__assign({}, oldProperties), newProperties), { extensions: extensions });
    }
    Extensions.updateResource = updateResource;
    function updateExtensions(resource, newExtensions) {
        var oldExtensions = resource.extensions, resourceProperties = __rest(resource, ["extensions"]);
        return __assign(__assign({}, resourceProperties), { extensions: __assign(__assign({}, oldExtensions), newExtensions) });
    }
    Extensions.updateExtensions = updateExtensions;
})(Extensions = exports.Extensions || (exports.Extensions = {}));
