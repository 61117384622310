import { useApiMutation, useApiQuery } from './base';
import { GitPlatform } from '../utils/constants';

export function usePostToken(gitplatform: GitPlatform) {
  const queryParam = gitplatform ? `?gitplatform=${gitplatform}` : '';
  return useApiMutation<null, { token: string }>(`/token${queryParam}`, 'POST');
}

export const useVerifyToken =
  useApiWithQueryParam<{ verified: boolean }>('/token/verify');

export const getTargetServer = useApiWithQueryParam('/token/targetServer');

function useApiWithQueryParam<T>(endpoint: string) {
  return (gitplatform: GitPlatform) => {
    const queryParam = gitplatform ? `?gitplatform=${gitplatform}` : '';
    return useApiQuery<T>(endpoint + queryParam);
  };
}

export function useUpdateCurrentToken() {
  return useApiMutation<null, { currentToken: string }>(
    '/token/update/currenttoken',
    'put',
  );
}

export function useGetAllTokenIds() {
  return useApiQuery<{ tokenIds: { id: string; type: string }[] }>(
    '/token/tokensid',
  );
}
