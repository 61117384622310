import React, { createRef, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Line, LINE_SCORING } from '@ceres/types';
import Color from 'color';
import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { FileType } from './utils/utils';
import highlight from 'highlight.js';
import './utils/github.css';

const LINE_COLOR_MAP = {
  [Line.Type.add]: '#ccffd8',
  [Line.Type.delete]: '#ffdce0',
  [Line.Type.noChange]: '#ffffff',
  [Line.Type.syntaxChange]: '#c3ddff',
  [Line.Type.spaceChange]: '#c3ddff',
  [Line.Type.comment]: '#e8d9fe',
  [Line.Type.syntaxLine]: '#c3ddff',
  [Line.Type.blank]: '#fff',
};

const getColors = (line: Line, operation) => {
  const lineColor = {
    left: LINE_COLOR_MAP[Line.Type.blank],
    right: LINE_COLOR_MAP[Line.Type.blank],
  };
  if (line.type === Line.Type.add) {
    lineColor.left = line.left
      ? LINE_COLOR_MAP[Line.Type.delete]
      : LINE_COLOR_MAP[Line.Type.blank];
    lineColor.right = LINE_COLOR_MAP[Line.Type.add];
  } else if (line.type === Line.Type.delete) {
    lineColor.left = LINE_COLOR_MAP[Line.Type.delete];
    lineColor.right = LINE_COLOR_MAP[Line.Type.blank];
  } else if (
    line.type === Line.Type.spaceChange ||
    line.type === Line.Type.syntaxChange ||
    line.type === Line.Type.syntaxLine ||
    line.type === Line.Type.comment ||
    line.type === Line.Type.blank
  ) {
    lineColor.left = LINE_COLOR_MAP[Line.Type.syntaxChange];
    lineColor.right = LINE_COLOR_MAP[Line.Type.syntaxChange];
  }
  if (operation == FileType.New) {
    lineColor.right = LINE_COLOR_MAP[Line.Type.add];
  }
  if (operation == FileType.Removed) {
    lineColor.left = LINE_COLOR_MAP[Line.Type.delete];
  }
  return lineColor;
};

const SideBySideDiffTable = (
  lines: Line[],
  operation,
  weight,
  classes,
  fileType,
  refs,
) => {
  return lines.map((line, index) => {
    const color = getColors(line, operation);
    const tooltip = `${line.type}: ${LINE_SCORING[line.type]} × ${weight}`;
    return (
      <TableRow key={index} style={{ width: '100%' }}>
        {line.left ? (
          <>
            <TableCell
              style={{
                backgroundColor: color.left,
              }}
              className={classes.number}
            >
              {line.left.lineNumber}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: Color(color.left).alpha(0.6),
              }}
              className={classes.tableCell}
            >
              <Tooltip title={tooltip}>
                <pre className={classes.pre}>
                  <code className={fileType} ref={refs[index]}>
                    {line.left.lineContent}
                  </code>
                </pre>
              </Tooltip>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell className={classes.emptyNumber} />
            <TableCell className={classes.empty} />
          </>
        )}
        {line.right ? (
          <>
            <TableCell
              className={classes.number}
              style={{
                borderLeft: '1px solid #ddd',
                backgroundColor: color.right,
              }}
            >
              {line.right.lineNumber}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: Color(color.right).alpha(0.6),
              }}
              className={classes.tableCell}
            >
              <Tooltip title={tooltip}>
                <pre className={classes.pre}>
                  <code
                    className={fileType}
                    ref={refs[lines.length * 2 - index]}
                  >
                    {line.right.lineContent}
                  </code>
                </pre>
              </Tooltip>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              className={classes.emptyNumber}
              style={{ borderLeft: '1px solid #ddd' }}
            />
            <TableCell className={classes.empty} />
          </>
        )}
      </TableRow>
    );
  });
};

const InlineDiffTable = (
  lines: Line[],
  operation,
  weight,
  classes,
  fileType,
  refs,
) => {
  let rightStack = [];
  const inlineDiffLines = [];

  const getInlineFormat = (
    line: {
      leftLineNumber?: number;
      rightLineNumber?: number;
      lineContent?: string;
    },
    index,
    classes,
    color,
    tooltip,
    fileType,
    ref,
  ) => {
    return (
      <TableRow key={index} style={{ width: '100%' }}>
        {
          <>
            <TableCell
              style={{
                backgroundColor: color,
                width: '3%',
              }}
              className={classes.number}
            >
              {line.leftLineNumber ? line.leftLineNumber : ''}
            </TableCell>
            <TableCell
              style={{
                backgroundColor: color,
                width: '3%',
              }}
              className={classes.number}
            >
              {line.rightLineNumber ? line.rightLineNumber : ''}
            </TableCell>
          </>
        }
        {
          <TableCell
            style={{
              backgroundColor: Color(color).alpha(0.6),
            }}
            className={classes.tableCell}
          >
            <Tooltip title={tooltip}>
              <pre className={classes.pre}>
                <code className={fileType} ref={ref}>
                  {line.lineContent}
                </code>
              </pre>
            </Tooltip>
          </TableCell>
        }
      </TableRow>
    );
  };
  lines.forEach((_, i) => {
    const color = getColors(lines[i] as any, operation);
    const tooltip = `${lines[i].type}: ${
      LINE_SCORING[lines[i].type]
    } × ${weight}`;
    if (lines[i].type == Line.Type.noChange) {
      rightStack.forEach(
        (_, index) => (rightStack[index].index += inlineDiffLines.length),
      );
      inlineDiffLines.push(...rightStack);
      rightStack = [];
      inlineDiffLines.push({
        line: {
          leftLineNumber: lines[i].left.lineNumber,
          rightLineNumber: lines[i].right.lineNumber,
          lineContent: lines[i].left.lineContent,
        },
        index: inlineDiffLines.length,
        classes: classes,
        color: color.left,
        tooltip: tooltip,
        fileType: fileType,
        ref: refs[i],
      });
      return;
    }

    if (lines[i].right) {
      rightStack.push({
        line: {
          leftLineNumber: null,
          rightLineNumber: lines[i].right.lineNumber,
          lineContent: lines[i].right.lineContent,
        },
        index: rightStack.length,
        classes: classes,
        color: color.right,
        tooltip: tooltip,
        fileType: fileType,
        ref: refs[lines.length * 2 - i],
      });
    }

    if (lines[i].left) {
      rightStack.push({
        line: {
          leftLineNumber: lines[i].left.lineNumber,
          rightLineNumber: null,
          lineContent: lines[i].left.lineContent,
        },
        index: rightStack.length,
        classes: classes,
        color: color.left,
        tooltip: tooltip,
        fileType: fileType,
        ref: refs[i],
      });
    } else {
      rightStack.forEach(
        (_, index) => (rightStack[index].index += inlineDiffLines.length),
      );
      inlineDiffLines.push(...rightStack);
      rightStack = [];
    }
  });

  return inlineDiffLines.map((diff) =>
    getInlineFormat(
      diff.line,
      diff.index,
      diff.classes,
      diff.color,
      diff.tooltip,
      diff.fileType,
      diff.ref,
    ),
  );
};

const DiffTable = ({ lines, weight, fileType, operation, viewmode }) => {
  const classes = useStyles();
  const [refs, setRefs] = useState([]);
  useEffect(() => {
    const arrRefs = [];
    lines.forEach(() => {
      arrRefs.push(createRef());
      arrRefs.push(createRef());
    });
    setRefs(arrRefs);
  }, [lines]);

  useEffect(() => {
    refs.forEach((ref) => {
      if (ref.current) {
        highlight.highlightElement(ref.current);
      }
    });
  }, [refs]);
  const tableBody = (
    viewmode === 'inline' ? InlineDiffTable : SideBySideDiffTable
  )(lines, operation, weight, classes, fileType, refs);
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='diff view table'>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles({
  table: {
    width: '100%',
  },
  tableCell: {
    borderBottom: 'none',
    paddingTop: 4,
    paddingBottom: 4,
    width: '45%',
  },
  pre: {
    margin: 0,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  number: {
    color: '#888',
    borderBottom: 'none',
    paddingTop: 4,
    paddingBottom: 4,
    fontFamily: 'monospace',
    padding: 0,
    textAlign: 'center',
    width: 0,
  },

  empty: {
    backgroundColor: '#fff',
    borderBottom: 'none',
    paddingTop: 4,
    paddingBottom: 4,
    width: '45%',
  },
  emptyNumber: {
    backgroundColor: '#fff',
    borderBottom: 'none',
    paddingTop: 4,
    paddingBottom: 4,
    padding: 0,
    textAlign: 'center',
    width: 0,
    maxWidth: 30,
  },
});

export default DiffTable;
