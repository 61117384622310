"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RepositoryMember = void 0;
var RepositoryMember;
(function (RepositoryMember) {
    var AccessLevel;
    (function (AccessLevel) {
        AccessLevel[AccessLevel["NO_ACCESS"] = 0] = "NO_ACCESS";
        AccessLevel[AccessLevel["MINIMAL_ACCESS"] = 5] = "MINIMAL_ACCESS";
        AccessLevel[AccessLevel["GUEST"] = 10] = "GUEST";
        AccessLevel[AccessLevel["REPORTER"] = 20] = "REPORTER";
        AccessLevel[AccessLevel["DEVELOPER"] = 30] = "DEVELOPER";
        AccessLevel[AccessLevel["MAINTAINER"] = 40] = "MAINTAINER";
        AccessLevel[AccessLevel["OWNER"] = 50] = "OWNER";
    })(AccessLevel = RepositoryMember.AccessLevel || (RepositoryMember.AccessLevel = {}));
})(RepositoryMember = exports.RepositoryMember || (exports.RepositoryMember = {}));
