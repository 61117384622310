import React, { useContext, useState } from 'react';
import { GitPlatform } from '../utils/constants';
import { useGetAllTokenIds } from '../api/token';

export function usePlatformContext() {
  return useContext(PlatformContext);
}

function usePlatformState(): PlatformContextState {
  const [platform, setPlatform] = useState<GitPlatform>(null);
  const [currentTokenId, setCurrentTokenId] = useState<string | null>(null);
  const { data } = useGetAllTokenIds();

  return {
    platform,
    setPlatform,
    setCurrentTokenId,
    currentTokenId,
    tokenIds: data,
  };
}

interface PlatformContextState {
  setPlatform: (platform: GitPlatform) => void;
  platform: GitPlatform;
  currentTokenId: string;
  setCurrentTokenId: (tokenId: string | null) => void;
  tokenIds: { tokenIds: { id: string; type: string }[] };
}

const PlatformContextDefault: PlatformContextState = {
  setPlatform: () => null,
  platform: null,
  currentTokenId: null,
  setCurrentTokenId: () => null,
  tokenIds: { tokenIds: [] },
};

const PlatformContext = React.createContext<PlatformContextState>(
  PlatformContextDefault,
);

export const PlatformContextProvider: React.FC = ({ children }) => {
  const value = usePlatformState();
  return (
    <PlatformContext.Provider value={value}>
      {children}
    </PlatformContext.Provider>
  );
};
