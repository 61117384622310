import { MergeRequest, Commit, ScoreOverride } from '@ceres/types';
import { useTheme } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';
import { ApiResource } from '../../api/base';
import ScoringChip from './ScoringChip';
import SmartDate from '../../shared/components/SmartDate';
import { useFilterContext } from '../../contexts/FilterContext';
import OverridePopper from './OverridePopper';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { useGetCommits } from '../../api/commit';

const StyledAccordionDetails = styled(AccordionDetails)`
  &&& {
    display: block;
  }
`;

interface CommitOrMergeRequestRendererProps {
  mergeRequest?: ApiResource<MergeRequest>;
  commit?: ApiResource<Commit>;
  active?: boolean;
  filteredAuthorEmails?: string[];
  onClickSummary?: () => void;
  shrink?: boolean;
  startDate?: string;
  endDate?: string;
  updated_at?: string;
  created_at?: string;
  state?: string;
}

function shortenTitle(title: string, shrink?: boolean) {
  const maxLength = shrink ? 50 : 50;
  if (title.length < maxLength) {
    return title;
  }
  return title.substr(0, maxLength) + '...';
}

function getSumAndHasOverride(
  emails: string[],
  commitScoreSums: MergeRequest['extensions']['commitScoreSums'],
) {
  let hasOverride = false;
  let score = 0;
  Object.keys(commitScoreSums).forEach((authorEmail) => {
    if (emails.length === 0 || emails.includes(authorEmail)) {
      hasOverride = commitScoreSums[authorEmail].hasOverride || hasOverride;
      score += commitScoreSums[authorEmail].sum;
    }
  });
  return {
    hasOverride,
    score,
  };
}

function getSumCommitScore(commits: ApiResource<Commit>[]): string {
  const sumCommitScore = commits?.reduce((accummulator: number, commit) => {
    return (
      accummulator +
      ScoreOverride.computeScore(
        commit.extensions?.override,
        commit?.extensions?.score,
      )
    );
  }, 0);

  return sumCommitScore?.toFixed(1);
}

const CommitOrMergeRequestRenderer: React.FC<
  CommitOrMergeRequestRendererProps
> = ({
  active,
  mergeRequest,
  filteredAuthorEmails,
  commit,
  onClickSummary,
  children,
  shrink,
  startDate,
  endDate,
}) => {
  const theme = useTheme();
  const isMerged = mergeRequest?.state === 'merged';
  const isOpen = mergeRequest?.state === 'opened';
  const isLate =
    DateTime.fromISO(mergeRequest?.merged_at) > DateTime.fromISO(endDate) &&
    isMerged;
  const title = commit
    ? commit.title
    : isMerged
    ? isLate
      ? '[Late MR/PR] ' + mergeRequest?.title
      : mergeRequest?.title
    : isOpen
    ? '[Open MR/PR] ' + mergeRequest?.title
    : '';
  const mergeRequestNumber = commit
    ? 'DTM: ' + commit.title
    : 'MR#' + mergeRequest?.iid;
  const author = mergeRequest?.author.name || commit?.committer_name;
  const extensions = (commit || mergeRequest).extensions;
  const isExcluded = extensions?.override?.exclude;
  const hasOverride = ScoreOverride.hasOverride(extensions?.override);
  const fileNameTextDecoration = isExcluded ? 'line-through' : '';
  const date =
    commit?.created_at ||
    (mergeRequest
      ? mergeRequest.merged_at || mergeRequest.created_at
      : undefined);
  const diffHasOverride =
    mergeRequest?.extensions?.diffHasOverride ||
    commit?.extensions?.diffHasOverride ||
    hasOverride;

  const diffScoreSum = ScoreOverride.computeScore(
    extensions?.override,
    mergeRequest?.extensions?.diffScore || commit?.extensions?.score,
  ).toFixed(1);

  const accordionColor = mergeRequest ? '' : '#f7ebef';
  const warningColor_red = '#FF6666';
  const warningColor_pink = '#FFC0CB';
  const warningColor_green = '#90EE90';
  const { emails } = useFilterContext();
  const { hasOverride: commitHasOverride } = getSumAndHasOverride(
    emails || [],
    mergeRequest?.extensions?.commitScoreSums || {},
  );

  const commits = mergeRequest
    ? useGetCommits({
        merge_request: mergeRequest?.meta.id,
      }).data
    : null;

  const mergeScore = mergeRequest
    ? getSumCommitScore(
        commits?.results.filter(
          (commit) =>
            DateTime.fromISO(startDate) <=
              DateTime.fromISO(commit.committed_date) &&
            DateTime.fromISO(commit.committed_date) <=
              DateTime.fromISO(endDate) &&
            (filteredAuthorEmails.length === 0 ||
              filteredAuthorEmails.includes(commit.author_email)),
        ),
      )
    : null;

  return (
    <Accordion
      expanded={active}
      TransitionProps={{ timeout: 0, unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={onClickSummary}
        style={{
          background: active
            ? theme.palette.primary.light
            : isOpen
            ? warningColor_red
            : isLate
            ? warningColor_green
            : commit
            ? warningColor_pink
            : accordionColor,
        }}
      >
        <Grid container>
          <Grid item xs={shrink ? 8 : 5}>
            <Grid container alignItems='center' spacing={2}>
              {hasOverride && (
                <Grid item>
                  <OverridePopper override={extensions.override} />
                </Grid>
              )}
              <Grid item>
                <Typography
                  style={{
                    textDecoration: fileNameTextDecoration,
                  }}
                >
                  {shortenTitle(mergeRequestNumber + ': ' + title, shrink)}
                </Typography>
              </Grid>
            </Grid>
            {shrink && (
              <Grid container justify='space-between'>
                <Typography variant='body2' color='textSecondary'>
                  {author}
                </Typography>
                <Box pr={4}>
                  <Typography variant='body2' color='textSecondary'>
                    <SmartDate>{date}</SmartDate>
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          {shrink ? (
            <>
              <Grid item xs={2}>
                <Typography align='right'>
                  <ScoringChip
                    hasOverride={diffHasOverride}
                    lineThrough={isOpen || isLate}
                  >
                    {diffScoreSum}
                  </ScoringChip>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align='right'>
                  <ScoringChip hasOverride={commitHasOverride}>
                    {mergeScore}
                  </ScoringChip>
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={2}>
                <Typography>{author}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography>
                  <SmartDate>{date}</SmartDate>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography align='right'>
                  <ScoringChip
                    hasOverride={diffHasOverride}
                    lineThrough={isOpen || isLate}
                  >
                    {diffScoreSum}
                  </ScoringChip>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align='right'>
                  <ScoringChip hasOverride={commitHasOverride}>
                    {mergeScore}
                  </ScoringChip>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionSummary>
      {mergeRequest && (
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      )}
    </Accordion>
  );
};

export default CommitOrMergeRequestRenderer;
