"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertPRToMR = void 0;
function convertPRToMR(mrMappings) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var PR_state = mrMappings.state === 'closed'
        ? mrMappings.merged_at === null
            ? 'closed' // rejected
            : 'merged' // merged
        : 'opened';
    var mr = {
        id: mrMappings.id,
        iid: mrMappings.number,
        project_id: mrMappings.base.repo.id,
        title: mrMappings.title,
        description: mrMappings.body,
        state: PR_state,
        created_at: mrMappings.created_at,
        updated_at: mrMappings.updated_at,
        merged_at: mrMappings.merged_at,
        merged_by: (_b = (_a = mrMappings.merged_at) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.login,
        closed_at: mrMappings.closed_at,
        closed_by: (_d = (_c = mrMappings.closed_at) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.login,
        target_branch: mrMappings.base.ref,
        source_branch: mrMappings.head.ref,
        user_notes_count: 0,
        upvotes: 0,
        downvotes: 0,
        author: {
            id: mrMappings.user.id,
            name: mrMappings.user.login,
            username: mrMappings.user.login,
            state: 'n/a',
            avatar_url: mrMappings.user.avatar_url,
            web_url: mrMappings.user.html_url,
        },
        assignees: mrMappings.assignees.map(function (a) { return ({
            id: a.id,
            name: a.login,
            username: a.login,
            state: a.state,
            avatar_url: a.avatar_url,
            web_url: a.html_url,
        }); }),
        assignee: {
            id: (_e = mrMappings.assignee) === null || _e === void 0 ? void 0 : _e.id,
            name: (_f = mrMappings.assignee) === null || _f === void 0 ? void 0 : _f.login,
            username: (_g = mrMappings.assignee) === null || _g === void 0 ? void 0 : _g.login,
            state: (_h = mrMappings.assignee) === null || _h === void 0 ? void 0 : _h.state,
            avatar_url: (_j = mrMappings.assignee) === null || _j === void 0 ? void 0 : _j.avatar_url,
            web_url: (_k = mrMappings.assignee) === null || _k === void 0 ? void 0 : _k.html_url,
        },
        reviewers: mrMappings.requested_reviewers.map(function (a) { return ({
            id: a.id,
            name: a.login,
            username: a.login,
            state: a.state,
            avatar_url: a.avatar_url,
            web_url: a.html_url,
        }); }),
        source_project_id: mrMappings.head.repo.id,
        target_project_id: mrMappings.base.repo.id,
        labels: mrMappings.labels.map(function (l) { return l.name; }),
        work_in_progress: mrMappings.draft,
        milestone: (_l = mrMappings.milestone) === null || _l === void 0 ? void 0 : _l.title,
        merge_when_pipeline_succeeds: false,
        merge_status: 'n/a',
        sha: mrMappings.head.sha,
        merge_commit_sha: mrMappings.merge_commit_sha,
        squash_commit_sha: mrMappings.merge_commit_sha,
        discussion_locked: false,
        should_remove_source_branch: false,
        force_remove_source_branch: false,
        reference: mrMappings.number.toString(),
        references: {
            short: "!" + mrMappings.number,
            relative: "!" + mrMappings.number,
            full: "!" + mrMappings.number,
        },
        web_url: mrMappings.html_url,
        time_stats: {
            time_estimate: 0,
            total_time_spent: 0,
            human_time_estimate: null,
            human_total_time_spent: null,
        },
        squash: false,
        task_completion_status: {
            count: 0,
            completed_count: 0,
        },
        has_conflicts: false,
        blocking_discussions_resolved: false,
        approvals_before_merge: 0,
    };
    return mr;
}
exports.convertPRToMR = convertPRToMR;
