import React from 'react';
import DefaultPageLayout from '../../shared/components/DefaultPageLayout';
import APIKeyModal from './APIKeyModal';

const UserSettings: React.FC = () => {
  return (
    <div>
      <DefaultPageLayout>
        <APIKeyModal />
      </DefaultPageLayout>
    </div>
  );
};

export default UserSettings;
