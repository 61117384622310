import React, { useContext, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { UpdateScoringPayload, useUpdateScoring } from '../api/scoring';

export function useUpdateScoringContext() {
  return useContext(ProgressContext);
}

function useUpdateScoringState(): UpdateScoringContextState {
  const [isScoreEvaluating, setIsScoreEvaluating] = useState<boolean>(false);
  const { mutate: updateScoring, isLoading: updateScoreLoading } =
    useUpdateScoring();

  return {
    isScoreEvaluating,
    setIsScoreEvaluating,
    updateScoreLoading,
    updateScoring,
  };
}

interface UpdateScoringContextState {
  setIsScoreEvaluating: (isScoreEvaluating: boolean) => void;
  isScoreEvaluating: boolean;
  updateScoreLoading: boolean;
  updateScoring: UseMutateFunction<void, any, UpdateScoringPayload, unknown>;
}

const ProgressContextDefault: UpdateScoringContextState = {
  setIsScoreEvaluating: () => null,
  isScoreEvaluating: false,
  updateScoreLoading: false,
  updateScoring: () => null,
};

const ProgressContext = React.createContext<UpdateScoringContextState>(
  ProgressContextDefault,
);

export const ProgressContextProvider: React.FC = ({ children }) => {
  const value = useUpdateScoringState();
  return (
    <ProgressContext.Provider value={value}>
      {children}
    </ProgressContext.Provider>
  );
};
