import Chip, { ChipProps } from '@material-ui/core/Chip';
import React from 'react';
import styled from 'styled-components';

interface ScoringChipProps {
  children: ChipProps['label'];
  hasOverride?: boolean;
  lineThrough?: boolean;
  size?: ChipProps['size'];
}

const StyledChip = styled(Chip)<{
  hasOverride?: boolean;
  lineThrough?: boolean;
}>`
  background: ${(p) => (p.hasOverride ? 'orange' : 'transparent')};
  text-decoration: ${(p) => (p.lineThrough ? 'line-through' : '')};
`;

const ScoringChip: React.FC<ScoringChipProps> = ({
  size,
  children,
  hasOverride,
  lineThrough,
}) => {
  return (
    <StyledChip
      size={size}
      label={children}
      hasOverride={hasOverride}
      lineThrough={lineThrough}
    />
  );
};

ScoringChip.defaultProps = {
  size: 'medium',
};

export default ScoringChip;
