"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertRepoDataToRepository = void 0;
function convertRepoDataToRepository(repoMappings) {
    var repo_name_components = repoMappings.full_name.split('/');
    var repository = {
        id: repoMappings.id,
        needs_recalculation: false,
        description: repoMappings.description,
        name: repoMappings.name,
        name_with_namespace: repo_name_components[0] + ' / ' + repo_name_components[1],
        path: repoMappings.name,
        path_with_namespace: repoMappings.full_name,
        created_at: repoMappings.created_at,
        default_branch: repoMappings.default_branch,
        tag_list: [],
        ssh_url_to_repo: repoMappings.ssh_url,
        http_url_to_repo: repoMappings.clone_url,
        web_url: repoMappings.html_url,
        readme_url: '',
        avatar_url: repoMappings.owner.avatar_url,
        forks_count: repoMappings.forks_count,
        star_count: repoMappings.stargazers_count,
        last_activity_at: repoMappings.pushed_at,
        namespace: {
            id: repoMappings.id,
            name: repo_name_components[1],
            path: repo_name_components[0],
            kind: '',
            full_path: repoMappings.full_name,
            parent_id: undefined,
            avatar_url: repoMappings.owner.avatar_url,
            web_url: repoMappings.owner.html_url,
        },
        _links: {
            self: '',
            issues: '',
            merge_requests: '',
            repo_branches: '',
            labels: '',
            events: '',
            members: '',
        },
        empty_repo: repoMappings.fork,
        archived: repoMappings.archived,
        visibility: repoMappings.visibility,
        owner: {
            id: repoMappings.owner.id,
            name: repoMappings.owner.login,
            username: repoMappings.owner.login,
            state: '',
            avatar_url: repoMappings.owner.avatar_url,
            web_url: repoMappings.owner.html_url,
        },
        container_registry_enabled: false,
        container_expiration_policy: {
            cadence: '',
            enabled: false,
            keep_n: 0,
            older_than: '',
            name_regex: '',
            next_run_at: '',
        },
        issues_enabled: repoMappings.has_issues,
        merge_requests_enabled: repoMappings.has_projects,
        wiki_enabled: repoMappings.has_wiki,
        jobs_enabled: repoMappings.has_downloads,
        snippets_enabled: repoMappings.has_pages,
        service_desk_enabled: false,
        can_create_merge_request_in: false,
        issues_access_level: '',
        repository_access_level: '',
        merge_requests_access_level: '',
        forking_access_level: '',
        wiki_access_level: '',
        builds_access_level: '',
        snippets_access_level: '',
        pages_access_level: '',
        operations_access_level: '',
        analytics_access_level: '',
        shared_runners_enabled: false,
        lfs_enabled: repoMappings.has_projects,
        creator_id: 0,
        import_status: '',
        open_issues_count: repoMappings.open_issues_count,
        request_access_enabled: false,
        only_allow_merge_if_all_discussions_are_resolved: false,
        remove_source_branch_after_merge: false,
        printing_merge_request_link_enabled: false,
        merge_method: '',
        suggestion_commit_message: '',
        auto_devops_enabled: false,
        auto_devops_deploy_strategy: '',
        autoclose_referenced_issues: false,
        public_jobs: false,
        ci_config_path: '',
        shared_with_groups: [],
        build_timeout: 0,
        auto_cancel_pending_pipelines: '',
        ci_default_git_depth: 0,
        only_allow_merge_if_pipeline_succeeds: false,
        permissions: {
            project_access: {
                access_level: 0,
                notification_level: 0,
            },
            group_access: {
                access_level: 0,
                notification_level: 0,
            },
        },
    };
    return repository;
}
exports.convertRepoDataToRepository = convertRepoDataToRepository;
