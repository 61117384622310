"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertRepoMemberMappingsToRepositoryMember = void 0;
function convertRepoMemberMappingsToRepositoryMember(mappings) {
    var repositoryMember = {
        id: mappings.id,
        name: mappings.login,
        username: mappings.login,
        state: mappings.type,
        avatar_url: mappings.avatar_url,
        web_url: mappings.html_url,
        access_level: mappings.site_admin
            ? 50
            : mappings.permissions.admin
                ? 40
                : mappings.permissions.maintain
                    ? 30
                    : 0,
        expires_at: new Date(0),
        email: 'n/a',
    };
    return repositoryMember;
}
exports.convertRepoMemberMappingsToRepositoryMember = convertRepoMemberMappingsToRepositoryMember;
