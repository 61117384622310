"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertIssueMappingsToIssue = void 0;
function convertIssueMappingsToIssue(issueMappings) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var issue = {
        id: issueMappings.id,
        iid: issueMappings.number,
        title: issueMappings.title,
        created_at: issueMappings.created_at,
        moved_to_id: undefined,
        labels: issueMappings.labels.map(function (label) { return label.name; }),
        state: issueMappings.state,
        description: issueMappings.body,
        author: {
            id: issueMappings.user.id,
            name: issueMappings.user.login,
            username: issueMappings.user.login,
            state: 'active',
            avatar_url: issueMappings.user.avatar_url,
            web_url: issueMappings.user.html_url,
        },
        upvotes: issueMappings.reactions['+1'],
        downvotes: issueMappings.reactions['-1'],
        merge_requests_count: 0,
        user_notes_count: 0,
        milestone: {
            project_id: (_a = issueMappings.milestone) === null || _a === void 0 ? void 0 : _a.project_id,
            description: (_b = issueMappings.milestone) === null || _b === void 0 ? void 0 : _b.description,
            id: (_c = issueMappings.milestone) === null || _c === void 0 ? void 0 : _c.id,
            iid: (_d = issueMappings.milestone) === null || _d === void 0 ? void 0 : _d.number,
            title: (_e = issueMappings.milestone) === null || _e === void 0 ? void 0 : _e.title,
            state: (_f = issueMappings.milestone) === null || _f === void 0 ? void 0 : _f.state,
            due_date: (_g = issueMappings.milestone) === null || _g === void 0 ? void 0 : _g.due_date,
            created_at: (_h = issueMappings.milestone) === null || _h === void 0 ? void 0 : _h.created_at,
            updated_at: (_j = issueMappings.milestone) === null || _j === void 0 ? void 0 : _j.updated_at,
        },
        due_date: '',
        project_id: 0,
        assignees: issueMappings.assignees.map(function (assignee) {
            return {
                id: assignee.id,
                name: assignee.login,
                username: assignee.login,
                state: 'active',
                avatar_url: assignee.avatar_url,
                web_url: assignee.html_url,
            };
        }),
        assignee: {
            id: (_k = issueMappings.assignee) === null || _k === void 0 ? void 0 : _k.id,
            name: (_l = issueMappings.assignee) === null || _l === void 0 ? void 0 : _l.login,
            username: (_m = issueMappings.assignee) === null || _m === void 0 ? void 0 : _m.login,
            state: 'active',
            avatar_url: (_o = issueMappings.assignee) === null || _o === void 0 ? void 0 : _o.avatar_url,
            web_url: (_p = issueMappings.assignee) === null || _p === void 0 ? void 0 : _p.html_url,
        },
        updated_at: issueMappings.updated_at,
        closed_at: issueMappings.closed_at,
        closed_by: 'undefined',
        web_url: issueMappings.html_url,
        references: {
            short: issueMappings.html_url,
            relative: issueMappings.html_url,
            full: issueMappings.html_url,
        },
        time_stats: {
            time_estimate: 0,
            total_time_spent: 0,
            human_time_estimate: '',
            human_total_time_spent: '',
        },
        has_tasks: false,
        task_status: 'undefined',
        confidential: false,
        discussion_locked: false,
        _links: {
            self: issueMappings.html_url,
            notes: issueMappings.html_url,
            award_emoji: issueMappings.html_url,
            project: issueMappings.html_url,
        },
        task_completion_status: {
            count: 0,
            completed_count: 0,
        },
    };
    return issue;
}
exports.convertIssueMappingsToIssue = convertIssueMappingsToIssue;
