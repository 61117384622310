import { SnackbarProvider } from 'notistack';
import React from 'react';
import './App.css';
import { Router } from './Router';
import ThemeProvider from './ThemeProvider';
import { AuthContextProvider } from '../contexts/AuthContext';
import { RepositoryContextProvider } from '../contexts/RepositoryContext';
import { PlatformContextProvider } from '../contexts/PlatformContext';
import { ProgressContextProvider } from '../contexts/UpdateScoringContext';
import { FilterContextProvider } from '../contexts/FilterContext';
import LuxonUtils from '@date-io/luxon';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const App = () => {
  return (
    <PlatformContextProvider>
      <ProgressContextProvider>
        <AuthContextProvider>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <RepositoryContextProvider>
              <FilterContextProvider>
                <ThemeProvider>
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <Router />
                  </SnackbarProvider>
                </ThemeProvider>
              </FilterContextProvider>
            </RepositoryContextProvider>
          </MuiPickersUtilsProvider>
        </AuthContextProvider>
      </ProgressContextProvider>
    </PlatformContextProvider>
  );
};

export default App;
