import React, { useEffect, useState, FC } from 'react';
import { Backdrop, makeStyles, Modal, Fade, Button } from '@material-ui/core';
import Form from './ApiForm';
import { usePlatformContext } from '../../../contexts/PlatformContext';
import { GITHUB, GITLAB, GitPlatform } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '420px',
    minHeight: '380px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  btnContainer: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
}));

const APIKeyModal: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [platform, setplatform] = useState<GitPlatform>('gitlab');
  const { platform: contextPlatform } = usePlatformContext();

  useEffect(() => {
    setOpen(true);
  }, [contextPlatform]);

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.btnContainer}>
              <Button
                variant='contained'
                color={platform === GITLAB ? 'primary' : 'default'}
                onClick={() => {
                  setplatform(GITLAB);
                }}
              >
                {GITLAB}
              </Button>
              <Button
                variant='contained'
                color={platform === GITHUB ? 'primary' : 'default'}
                onClick={() => {
                  setplatform(GITHUB);
                }}
              >
                {GITHUB}
              </Button>
            </div>
            <Form gitContext={platform} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default APIKeyModal;
