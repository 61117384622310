import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  usePostToken,
  useVerifyToken,
  getTargetServer,
  useUpdateCurrentToken,
} from '../../../api/token';
import { usePlatformContext } from '../../../contexts/PlatformContext';
import { GitPlatform } from '../../../utils/constants';
import { useGetAllTokenIds } from '../../../api/token';
import { useProfile } from '../../../api/user';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(2),
      width: '25ch',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    '& > *': {
      margin: theme.spacing(1),
    },
    borderRadius: '30px',
  },
}));

const Form = ({ gitContext }: { gitContext: GitPlatform }) => {
  const classes = useStyles();
  const [apiKey, setApiKey] = useState('');
  const [error, setError] = useState(false);
  const { mutate } = usePostToken(gitContext);
  const { mutate: mutateCurrentTokenId } = useUpdateCurrentToken();
  const { setPlatform } = usePlatformContext();
  const targetURL = String(getTargetServer(gitContext).data).split('/api/')[0];
  const { invalidate: invalidateToken, data } = useVerifyToken(gitContext);
  const {
    invalidate: invalidateTokenIds,
    data: tokenData,
    refetchQueries: refetchTokens,
  } = useGetAllTokenIds();
  const { invalidate: invalidateUser } = useProfile();
  const { push } = useHistory();

  useEffect(() => {
    setApiKey('');
    setError(false);
  }, [gitContext]);

  const changeApiKey = (event: any) => {
    setError(false);
    setApiKey(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    mutate(
      { token: apiKey },
      {
        onSuccess: async () => {
          await invalidateToken();
          await invalidateTokenIds();
          await invalidateUser();
          await refetchTokens();
        },
        onError: () => {
          setError(true);
        },
      },
    );
  };

  const handlePlatformChoice = () => {
    setPlatform(gitContext);
    mutateCurrentTokenId(
      {
        currentToken: tokenData?.tokenIds?.find((id) => id.type == gitContext)
          ?.id,
      },
      {
        onSuccess: async () => {
          await invalidateToken();
          await invalidateTokenIds();
          await invalidateUser();
          await refetchTokens();
        },
      },
    );
    // window.location.replace('/repository');
    push('/repository');
  };

  return (
    <>
      {!data?.verified ? (
        <form
          className={classes.root}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit}
        >
          {error && (
            <div style={{ color: 'red' }}>
              {' '}
              Unable to validate token. Please try again{' '}
            </div>
          )}
          <h3>Please Enter your {gitContext} API Key</h3>
          <h4>
            Target Server: <a href={targetURL}>{targetURL}</a>
          </h4>
          <TextField
            value={apiKey}
            onChange={changeApiKey}
            id='standard-basic'
            label='Enter API Key'
          />
          <Button
            type='submit'
            className={classes.button}
            variant='contained'
            color='primary'
            disabled={apiKey.length <= 0}
          >
            Submit
          </Button>
        </form>
      ) : (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <h3>{gitContext} API Key Verified</h3>
          <p style={{ marginBottom: '100px' }}>Choose {gitContext}?</p>
          <Button
            onClick={handlePlatformChoice}
            variant='contained'
            color='secondary'
            size='large'
            style={{ backgroundColor: '#FCA311' }}
          >
            Confirm
          </Button>
        </div>
      )}
    </>
  );
};

export default Form;
