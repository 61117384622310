"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINE_SCORING = exports.Line = void 0;
var Line;
(function (Line) {
    var Type;
    (function (Type) {
        Type["gap"] = "gap";
        Type["add"] = "add";
        Type["delete"] = "delete";
        Type["noChange"] = "no-change";
        Type["syntaxChange"] = "syntax-change";
        Type["spaceChange"] = "space-change";
        Type["blank"] = "blank";
        Type["comment"] = "comment";
        Type["syntaxLine"] = "syntax-line";
    })(Type = Line.Type || (Line.Type = {}));
})(Line = exports.Line || (exports.Line = {}));
exports.LINE_SCORING = (_a = {},
    _a[Line.Type.gap] = 0,
    _a[Line.Type.add] = 1,
    _a[Line.Type.delete] = 0.2,
    _a[Line.Type.noChange] = 0,
    _a[Line.Type.spaceChange] = 0,
    _a[Line.Type.syntaxChange] = 0.2,
    _a[Line.Type.blank] = 0,
    _a[Line.Type.syntaxLine] = 0,
    _a[Line.Type.comment] = 0,
    _a);
