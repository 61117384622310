"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertDiffMappingsToDiff = void 0;
function convertDiffMappingsToDiff(mappings) {
    var diff = {
        old_path: 'n/a',
        new_path: mappings.filename,
        a_mode: mappings.status,
        b_mode: mappings.status,
        new_file: mappings.status === 'added',
        renamed_file: mappings.status === 'renamed',
        deleted_file: mappings.status === 'removed',
        diff: mappings.patch ? mappings.patch : '',
    };
    return diff;
}
exports.convertDiffMappingsToDiff = convertDiffMappingsToDiff;
