"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Operation = void 0;
var Operation;
(function (Operation) {
    var Type;
    (function (Type) {
        Type["FETCH_REPOSITORIES"] = "fetch-repositories";
        Type["SYNC_REPOSITORY"] = "sync-repository";
        Type["DELETE_REPOSITORY"] = "delete-repository";
    })(Type = Operation.Type || (Operation.Type = {}));
    var Status;
    (function (Status) {
        Status["PENDING"] = "pending";
        Status["PROCESSING"] = "processing";
        Status["COMPLETED"] = "completed";
        Status["TERMINATED"] = "terminated";
    })(Status = Operation.Status || (Operation.Status = {}));
    function buildSyncRepositoryPayload(repositoryId, name) {
        return {
            type: Operation.Type.SYNC_REPOSITORY,
            input: {
                repository_id: repositoryId,
                name: name,
            },
        };
    }
    Operation.buildSyncRepositoryPayload = buildSyncRepositoryPayload;
    function buildDeleteRepositoryPayload(repositoryId) {
        return {
            type: Operation.Type.DELETE_REPOSITORY,
            input: {
                repository_id: repositoryId,
            },
        };
    }
    Operation.buildDeleteRepositoryPayload = buildDeleteRepositoryPayload;
})(Operation = exports.Operation || (exports.Operation = {}));
