"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Note = void 0;
var Note;
(function (Note) {
    var Type;
    (function (Type) {
        Type["issueComment"] = "issue-comment";
        Type["mergeRequestComment"] = "merge-request-comment";
    })(Type = Note.Type || (Note.Type = {}));
    var NoteableType;
    (function (NoteableType) {
        NoteableType["issue"] = "Issue";
        NoteableType["merge_request"] = "MergeRequest";
    })(NoteableType = Note.NoteableType || (Note.NoteableType = {}));
})(Note = exports.Note || (exports.Note = {}));
